import { type Atom, atom } from "jotai";

import { browserOfflineAtom } from "./browser-offline.atom";

export type OfflineValue = boolean | null;

/**
 * It is an atom containing an atom.
 * It is designed to be overridden by other implementations that are more specific.
 * For example on Tizen there's a custom API to detect when we are offline or not.
 */
export const offlineAtom = atom<Atom<OfflineValue>>(browserOfflineAtom);
offlineAtom.debugPrivate = true;

/**
 * Determines if we are online or offline.
 * The logic can be overridden by setting a new Atom here which conforms to the same interface.
 */
export const isOfflineAtom = atom<OfflineValue, [Atom<OfflineValue>], void>(
  (get) => get(get(offlineAtom)),
  (_, set, v: Atom<OfflineValue>) => set(offlineAtom, v),
);
