import { VIDEO_ADS_CUSTOM_CLICKTRACKING_ELEMENT_ID } from "./constants";

/**
 * @returns The element that IMA SDK will attach the click event to when
 * `isCustomClickTrackingUsed()` is true. This will only happen on actual mobile
 * browsers
 */
export function getAdCustomClickTrackingElement(): HTMLDivElement | undefined {
  const customVideoAdClickTrackingElement = document.getElementById(
    VIDEO_ADS_CUSTOM_CLICKTRACKING_ELEMENT_ID,
  );

  if (!(customVideoAdClickTrackingElement instanceof HTMLDivElement)) {
    return;
  }

  return customVideoAdClickTrackingElement;
}
