import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { pagedAtom, type PagedAtomReturnType } from "@sunrise/backend-ng-core";
import { recordingGroupsEpisodesAtom } from "@sunrise/backend-ng-recordings";
import type {
  AssetId,
  ChannelId,
  RecordingGroupId,
  RecordingId,
  TimeISOString,
} from "@sunrise/backend-types-core";

import type { SimplifiedListEpisodeData } from "./types";

export const pagedRecordingGroupsEpisodesAtom = atomFamily(
  (recordingGroupId: RecordingGroupId) => {
    const recording = pagedAtom(recordingGroupsEpisodesAtom(recordingGroupId));

    return atom<
      PagedAtomReturnType<
        Exclude<SimplifiedListEpisodeData, { epgId: unknown }>
      >
    >((get) => {
      const paged = get(recording);
      return {
        ...paged,
        items: paged.items.map((episode) => ({
          recordingId: episode.id as RecordingId,
          actualStart: episode.epg_start as TimeISOString,
          actualEnd: episode.epg_end as TimeISOString,
          channel: {
            id: episode.channel_id as ChannelId,
            name: episode.channel_name,
            logo: episode.channel_logo,
          },
          streamUrl: episode.stream_service_url,
          asset: {
            id: episode.asset.id as AssetId,
            title: episode.asset.title,
            subtitle: episode.asset.subtitle,
            seasonNumber: episode.asset.season_number,
            episodeNumber: episode.asset.episode_number,
          },
          paddingStartMinutes: episode.padding_start_minutes,
          paddingEndMinutes: episode.padding_end_minutes,
        })),
      };
    });
  },
);
