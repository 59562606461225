/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** DownloadVideoResolutions */
export enum DownloadVideoResolutions {
  Value288P = "288p",
  Value432P = "432p",
  Value576P = "576p",
  Value720P = "720p",
  Value1080P = "1080p",
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** StreamSchema */
export interface StreamSchema {
  /**
   * Stream URL
   * The URL of the stream
   */
  stream_url: string;
  /**
   * License URL
   * The URL of the license for the stream
   */
  license_url?: string | null;
}

/** ValidLanguages */
export enum ValidLanguages {
  En = "en",
  De = "de",
  Fr = "fr",
  It = "it",
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

import { HttpClient, RequestParams } from "@sunrise/backend-ng-core";
import type { TimeISOString } from "@sunrise/backend-types-core";

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  stream = {
    /**
     * @description This endpoint returns a live or replay stream for the given channel. To request a replay stream, please provide start and end. If only start is provided, the BE will calculate the end.
     *
     * @tags channels
     * @name GetChannelStreamStreamV1ChannelsChannelIdStreamGet
     * @summary Get Channel Stream
     * @request GET:/stream/v1/channels/{channel_id}/stream
     * @secure
     */
    getChannelStreamStreamV1ChannelsChannelIdStreamGet: (
      channelId: string,
      query?: {
        /** Start */
        start?: TimeISOString | null;
        /** End */
        end?: TimeISOString | null;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<StreamSchema, void | HTTPValidationError>({
        path: `/stream/v1/channels/${channelId}/stream`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint returns a recording stream for the given recording. IMPORTANT: The provider_recording_id is not our mongo id for the recording, but the one from the stream_provider.
     *
     * @tags recordings
     * @name GetRecordingStreamStreamV1RecordingsProviderRecordingIdStreamGet
     * @summary Get Recording Stream
     * @request GET:/stream/v1/recordings/{provider_recording_id}/stream
     * @secure
     */
    getRecordingStreamStreamV1RecordingsProviderRecordingIdStreamGet: (
      providerRecordingId: string,
      params: RequestParams = {},
    ) =>
      this.http.request<StreamSchema, void | HTTPValidationError>({
        path: `/stream/v1/recordings/${providerRecordingId}/stream`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint redirects the download request of the recording, to start the download. IMPORTANT: The provider_recording_id is not our mongo id for the recording, but the one from the stream_provider.
     *
     * @tags recordings
     * @name DownloadRecordingStreamV1RecordingsProviderRecordingIdDownloadGet
     * @summary Download Recording
     * @request GET:/stream/v1/recordings/{provider_recording_id}/download
     * @secure
     */
    downloadRecordingStreamV1RecordingsProviderRecordingIdDownloadGet: (
      providerRecordingId: string,
      query?: {
        /**
         * Video Resolution
         * @default "720p"
         */
        video_resolution?: DownloadVideoResolutions | null;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<any, void | HTTPValidationError>({
        path: `/stream/v1/recordings/${providerRecordingId}/download`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description This endpoint returns the requested vod stream.
     *
     * @tags vods
     * @name GetVodStreamStreamV1VodsVodIdStreamGet
     * @summary Get Vod Stream
     * @request GET:/stream/v1/vods/{vod_id}/stream
     * @secure
     */
    getVodStreamStreamV1VodsVodIdStreamGet: (vodId: string, params: RequestParams = {}) =>
      this.http.request<StreamSchema, void | HTTPValidationError>({
        path: `/stream/v1/vods/${vodId}/stream`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthStreamV1HealthGet
     * @summary Health
     * @request GET:/stream/v1/health
     */
    healthStreamV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/stream/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RootStreamV1Get
     * @summary Root
     * @request GET:/stream/v1
     */
    rootStreamV1Get: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/stream/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
