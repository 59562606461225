import { isAfter, isEqual } from "date-fns";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { ChannelId, EPGEntryId } from "@sunrise/backend-types-core";
import { selectCanReplay } from "@sunrise/jwt";
import { epgEntryByIdAtom } from "@sunrise/yallo-epg";
import type { UpsellErrorCode } from "@sunrise/yallo-upsell";
import { selectActiveReplayChannels, userAtom } from "@sunrise/yallo-user";

/**
 * returns isChannelActiveForReplay: true | UpsellErrorCode and function to check if replay is active long enough that can also return UpsellErrorCode
 */

export type ActiveReplayChannel = {
  isChannelActiveForReplay: true | UpsellErrorCode;
  checkIfReplayActiveLongEnough: (
    epgId: EPGEntryId,
    startTime?: Date,
  ) => Promise<UpsellErrorCode | true>;
};

export const activeReplayChannelAtom = atomFamily(
  (param: { channelId: ChannelId }) => {
    const innerAtom = atom(async (get): Promise<ActiveReplayChannel> => {
      const user = (await get(userAtom)).data;

      // TODO: When no user deal with it properly ...
      if (!user) {
        throw new Error("no user when checking replay permissions");
      }

      // Check if the user can even replay. If not, return UPSELL_NO_REPLAY on all fronts.
      const canReplay = get(selectCanReplay);

      if (!canReplay) {
        return {
          isChannelActiveForReplay: "UPSELL_NO_REPLAY",
          checkIfReplayActiveLongEnough: async () => "UPSELL_NO_REPLAY",
        };
      }

      const result = selectActiveReplayChannels(user);

      const channel = result?.find(
        (channels) => channels.channel_id === param.channelId,
      );

      if (!channel) {
        // Channel is not active for replay
        return {
          isChannelActiveForReplay: "UPSELL_REPLAY_NOT_ENABLED",
          checkIfReplayActiveLongEnough: async () =>
            "UPSELL_REPLAY_NOT_ENABLED",
        };
      }

      const checkIfReplayActiveLongEnough = async (
        epgId: EPGEntryId,
        epgStartTime?: Date,
      ) => {
        let startTime = epgStartTime;

        if (!startTime) {
          const epgEntry = (await get(epgEntryByIdAtom(epgId))).data;

          if (!epgEntry?.actualStart) {
            return "UPSELL_REPLAY_NOT_ENABLED_LONG_ENOUGH";
          }

          startTime = new Date(epgEntry.actualStart);
        }

        const activatedAt = new Date(channel.activated_at);

        return isAfter(startTime, activatedAt) ||
          isEqual(startTime, activatedAt)
          ? true
          : "UPSELL_REPLAY_NOT_ENABLED_LONG_ENOUGH";
      };

      return {
        isChannelActiveForReplay: true,
        checkIfReplayActiveLongEnough,
      };
    });

    innerAtom.debugLabel = `activeReplayChannel(${param.channelId})`;
    return innerAtom;
  },
);
