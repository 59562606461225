import type { ReactNode } from "react";
import { atomWithReducer, selectAtom } from "jotai/utils";
import { atom } from "jotai/vanilla";

import type { Translatable } from "@sunrise/i18n";
import { isDefined, type Nullable } from "@sunrise/utils";

import { scrollableDialogAtom } from "./scrollable-dialog.store";

/**
 * Base type for action dialog actions without the action property.
 */
export type ActionDialogActionBase = {
  label: Translatable;
  /**
   * When set to true, the focus will initially be on this item.
   * If there are multiple actions that have this set to true we will focus on the first one.
   */
  initialFocus?: boolean;

  /**
   * After timeout it will execute the action.
   */
  execAfterTimeout?: number;
  /**
   * Used to give it a unique key and also a unique data-testid.
   */
  key?: string;

  closeDisabled?: boolean;

  /**
   * Primary or secondary button styling on web.
   */
  primary?: boolean;
};

type ActionDialogActionWithAction = ActionDialogActionBase & {
  /**
   * TODO: Seems like there are a lot of cases where we pass empty functions. Maybe we can make this optional?
   */
  action: () => Promise<void> | void;
};

type ActionDialogActionWithUrl = ActionDialogActionBase & {
  url: string;
};

/**
 * Something that represents a button in the actions dialog.
 * Like a confirmation button or a cancel button.
 */
export type ActionDialogAction =
  | ActionDialogActionWithAction
  | ActionDialogActionWithUrl;

export type DialogDescription =
  | Translatable
  | { type: "component"; component: ReactNode };

/**
 * Common properties for any dialog.
 */
type Dialog = {
  title?: Translatable;
  /**
   * TODO: To deprecate.
   */
  lastFocusKey: string;
  /**
   * By default the modal will close on back (close-modal).
   * But you can override the behaviour here.
   */
  backBehaviour?: "close-modal" | "kill-app" | "blocked";
  id: string;
};

/**
 * The ActionsDialog requires us to give it a set of actions.
 */
export type ActionsDialog = Dialog & {
  /**
   * Each dialog type should have it explicitly set like this. Makes the typing easier.
   */
  type: "actions";
  title: Translatable;
  description?: DialogDescription;
  textAlignment?: "center" | "left";
  onTheSide?: boolean; // show the actions as a side-bar on the side of the page
  bgImage?: string;
  logo?: string;
  actions: ActionDialogAction[];
  technicalErrorName?: string;
};

/**
 * A dialog which presents the user with just the retry button.
 * The user can not exit out of it. Only way is to press retry.
 */
export type RetryDialog = Dialog & {
  type: "retry";
  title: Translatable;
  description?: DialogDescription;
  textAlignment?: "center" | "left";
  bgImage?: string;
  logo?: string;
  /**
   * The dialog component will call this function when the user presses the retry button.
   *
   * @returns
   *  A promise that will resolve when the request has been retried.
   *  This way the UI knows to close itself.
   */
  onRetry: () => Promise<void>;
  /**
   * To be called when the modal is closed.
   * This should tell the retry middleware to abort the retry and let the original error flow through.
   * Unless we already retried it of course.
   */
  onClose: () => void;
  backBehaviour: "kill-app";
  actionLabel?: Translatable;
};

export type ListDialogButton = {
  label: string;
  id: string;
};

export type ListDialogSection = {
  // TODO: Add a SectionId Opaque Type?
  title?: Translatable;
  options: ListDialogOption[];
};

type ListDialogOption = {
  label: Translatable;
  value: Nullable<string>;
};

export type ListDialog = Dialog & {
  type: "list";
  actionButtonLabel?: Translatable;
  onActionButtonClick?: () => void;
  buttonTextAlign?: "left" | "center" | "right";
  onButtonClick: (
    value: Nullable<string>,
    sectionIdx: Nullable<number>,
  ) => void;
  radioButtons?: boolean;
  sections: ListDialogSection[];
  activeOptions: Nullable<string>[];
};
export type Dialogs = ActionsDialog | RetryDialog | ListDialog;

export type DialogAtomState = {
  dialogsQueue: Dialogs[];
};

export function makeDialogsDefaultState(
  state?: Partial<DialogAtomState>,
): DialogAtomState {
  return {
    dialogsQueue: state?.dialogsQueue ?? [],
  };
}

const INITIAL_STATE = makeDialogsDefaultState();

type ActionOpen = {
  type: "dialog/open";
  payload: Dialogs;
};
type ActionClose = {
  type: "dialog/close";
  payload?: {
    id?: Nullable<string>;
  };
};
type ActionSetActiveOption = {
  type: "dialog/setActiveOptions";
  payload: { activeOption: ListDialogOption["value"]; sectionIdx: number };
};

type DialogsAction = ActionOpen | ActionClose | ActionSetActiveOption;

/**
 * All dialog types can be represented in the same atom.
 * A dialog is just a thing that can be set and removed.
 * It is open when the dialog attribute is not null.
 */
export const dialogAtom = atomWithReducer<DialogAtomState, DialogsAction>(
  INITIAL_STATE,
  dialogsAtomReducer,
);

export function dialogsAtomReducer(
  state: DialogAtomState,
  action: DialogsAction,
): DialogAtomState {
  switch (action.type) {
    case "dialog/open": {
      return {
        dialogsQueue: [...state.dialogsQueue, action.payload],
      };
    }
    case "dialog/close": {
      const id = action.payload?.id;
      const filteredArray = isDefined(id)
        ? state.dialogsQueue.filter((dialog) => dialog.id !== id)
        : [];

      return {
        dialogsQueue: !id ? state.dialogsQueue.slice(0, -1) : filteredArray,
      };
    }
    case "dialog/setActiveOptions": {
      const dialog = state.dialogsQueue[state.dialogsQueue.length - 1];

      // When we are not a dialog with options, ignore this action.
      if (dialog?.type !== "list") {
        return state;
      }

      // Else, accept the change.
      const newState = [...dialog.activeOptions];
      newState[action.payload.sectionIdx] = action.payload.activeOption;
      const newDialog = { ...dialog, activeOptions: newState };

      const idx = state.dialogsQueue.indexOf(dialog);

      return {
        dialogsQueue: state.dialogsQueue.map((d, i) =>
          i === idx ? newDialog : d,
        ),
      };
    }
  }
}

/*
 *
 * ACTIONS
 *
 */

export function actionDialogOpen(payload: ActionOpen["payload"]): ActionOpen {
  return {
    type: "dialog/open",
    payload,
  };
}

export function actionDialogClose(
  payload?: ActionClose["payload"],
): ActionClose {
  return {
    type: "dialog/close",
    payload,
  };
}

export function actionListDialogSetActiveId({
  value,
  sectionIdx,
}: {
  value: Nullable<string>;
  sectionIdx: number;
}): ActionSetActiveOption {
  return {
    type: "dialog/setActiveOptions",
    payload: {
      activeOption: value,
      sectionIdx,
    },
  };
}

/*
 *
 * SELECTORS
 *
 */

export const selectIsDialogOpen = atom(
  (get) =>
    !!get(dialogAtom).dialogsQueue.length || get(scrollableDialogAtom).isOpen,
);

export const selectCurrentlyOpenedDialog = selectAtom(
  dialogAtom,
  (state) => state.dialogsQueue[state.dialogsQueue.length - 1] ?? null,
);
