import type { ReactElement } from "react";
import clsx from "clsx";

import { VisuallyHidden } from "../visually-hidden";
import styles from "./spinner.module.css";

type SpinnerProps = {
  variant?: "small" | "large";
  label?: string;
  isActive: boolean;
} & Pick<CommonProps, "className" | "data-testid">;

const spinnerCount = 3;
const spinnerAnimationDelayMs = -150;
const spinners = Array.from(
  { length: spinnerCount },
  (_, idx) => ++idx * spinnerAnimationDelayMs + "ms",
).map((delay, idx) => (
  <div
    key={idx}
    aria-hidden="true"
    className={clsx(styles.circle)}
    style={{ animationDelay: delay }}
  />
));

export function Spinner({
  "data-testid": dataTestId,
  className,
  variant = "large",
  label,
  isActive,
}: SpinnerProps): ReactElement {
  return (
    <>
      {!!label && (
        <VisuallyHidden>
          <div role="status">{label}</div>
        </VisuallyHidden>
      )}
      {isActive ? (
        <div
          className={clsx(styles.root, styles[variant], className)}
          data-testid={`${dataTestId}.spinner`}
        >
          {spinners}
          <div aria-hidden="true" className={clsx(styles.circle)} />
        </div>
      ) : null}
    </>
  );
}
