import type {
  EPGEntryId,
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";

import type {
  BaseRecordingGroupSchema,
  BaseRecordingSchema,
  RecordingGroupStatus,
  RecordingStatus,
} from "./GeneratedApi";

/**
 * Expose only our wrapped types and backend enums or simple backend types we could use 1:1 in the frontend.
 */

export interface NgSingleRecording
  extends Omit<BaseRecordingSchema, "epg_start" | "epg_end"> {
  type: "recording";
  id: RecordingId;
  /**
   * EPG Start Time
   * The start time of the EPG
   */
  epg_start: Date;
  /**
   * EPG End Time
   * The end time of the EPG
   */
  epg_end: Date;
  /**
   * EPG id
   * The id of the epg used to create the recording
   */
  epg_entry_id: EPGEntryId;
}

export interface NgRecordingGroup extends BaseRecordingGroupSchema {
  type: "recording_group";
  id: RecordingGroupId;
}

export type NgRecording = NgSingleRecording | NgRecordingGroup;

type RecordingStatusForSingleRecording = {
  recordingId: RecordingId;
  status: RecordingStatus;
  type: "single";
};

type RecordingStatusForGroupRecording = {
  recordingId: RecordingGroupId;
  status: RecordingGroupStatus;
  type: "group";
};

type RecordingStatusForUnmappedRecording = {
  recordingId: RecordingGroupId | RecordingId;
  status:
    | RecordingStatusForSingleRecording["status"]
    | RecordingStatusForGroupRecording["status"];
  type: "unknown";
};

export type RecordingStatusForRecording =
  | RecordingStatusForSingleRecording
  | RecordingStatusForGroupRecording
  | RecordingStatusForUnmappedRecording;

export enum RecordingItemFilter {
  Recording = "recording",
  RecordingGroup = "recording_group",
  All = "all",
}
