import { atom } from "jotai";

import { jwtAtom, selectCanReplay } from "@sunrise/jwt";

import type { PlayerManagerPermissions } from "./yallo-common-player-manager.types";

export const playerPermissionsAtom = atom<PlayerManagerPermissions>((get) => {
  const { decodedPayload: jwt } = get(jwtAtom);

  if (!jwt) {
    return {
      canPlayLive: false,
      canReplay: false,
      showAds: false,
      showReplayAlternativeAds: false,
      showReplayFfwdAds: false,
    };
  }

  return {
    canReplay: get(selectCanReplay),
    showAds: jwt.feature_set.features.show_ads,
    canPlayLive: jwt.feature_set.features.can_play_live,
    showReplayAlternativeAds:
      jwt.feature_set.features.show_replay_alternative_ads,
    showReplayFfwdAds:
      jwt.feature_set.features.show_fast_forward_alternative_ads,
  };
});
