import { featureAtom } from "@sunrise/feature-flags";

export const preferNgStreamsFeatureAtom = featureAtom(
  "prefer-ng-streams",
  true,
  {
    description:
      "Prefer NG streams over legacy streams. If this is enabled we will reach out to the new stream endpoint to get the stream and license URL.",
  },
);
