import { isAxiosError } from "axios";
import { isNil } from "lodash";

import { PlayerContentType } from "@sunrise/backend-ng-events";
import type {
  RecordingStreamResponseLegacy,
  ReplayStream,
  SimpleStream,
  Stream,
} from "@sunrise/backend-types";
import type { ChannelId } from "@sunrise/backend-types-core";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import type { StreamResolveFunction } from "@sunrise/player-manager";
import { type Store } from "@sunrise/store";
import { nowSecondAtom } from "@sunrise/time";
import { deviceInfo } from "@sunrise/utils";
import { channelByIdAtom } from "@sunrise/yallo-channel-group";
import { epgEntryByIdAtom } from "@sunrise/yallo-epg";
import type { PlayRequest } from "@sunrise/yallo-player-types";
import { recordingByRecordingIdAtom } from "@sunrise/yallo-recordings";
import { getReplayStartTime } from "@sunrise/yallo-replay";
import { fetchStreamRequest, StreamNotFoundError } from "@sunrise/yallo-stream";

import { drmEnabledAtom } from "../drm-enabled.atom";

export async function legacyPlayRequestToStream(
  store: Store,
  request: PlayRequest,
): Promise<StreamResolveFunction> {
  const { url, offset } = await getStreamUrlForPlayRequest(store, request);

  const host = store.get(hostsAtom).api;
  if (isNil(host)) throw new Error("missing host");

  const { privateApi } = store.get(httpClientAtom);
  if (!privateApi) throw new Error("missing privateApi");

  // NOTE: For non-preflight requests, we need to make sure to ask the backend for the stream in the first phase.
  if (request.type === "recording") {
    const recordingRes =
      await fetchStreamRequest<RecordingStreamResponseLegacy>(
        privateApi,
        host,
        url,
      );

    return (): Promise<Stream> => {
      const stream: SimpleStream = {
        licenseUrl: recordingRes.license_url,
        type: recordingRes.stream_type,
        url: recordingRes.play_url,
        provider: recordingRes.provider,
      };

      return Promise.resolve(stream);
    };
  }

  const response = await fetchStreamRequest(privateApi, host, url);

  return (): Promise<Stream> => {
    const stream: SimpleStream = {
      licenseUrl: response.license_url,
      type: response.stream_type,
      url: response.url,
      provider: response.provider,
    };

    if (offset) {
      return Promise.resolve({
        ...stream,
        offset,
        markers: response.markers,
      } satisfies ReplayStream);
    }

    return Promise.resolve(stream);
  };
}

async function getStreamUrlForPlayRequest(
  store: Store,
  request: PlayRequest,
): Promise<{ url: string; offset?: number }> {
  const isNativeHls = deviceInfo.isSafari || deviceInfo.isIOS;

  switch (request.type) {
    case PlayerContentType.Live: {
      const url = await getChannelStreamUrl(store, request.channelId);
      return {
        url: [
          url,
          new URLSearchParams({
            ...(!store.get(drmEnabledAtom)
              ? { stream_type: isNativeHls ? "hls7" : "dash" }
              : {}),
          }).toString(),
        ].join("?"),
      };
    }
    case PlayerContentType.Replay: {
      // load epg details. Determine actual start of the epg stream.
      // Even though we have a start time, we should also be allowed to start the replay if the program is starting before but ending after the replay window start.
      const [epg, url] = await Promise.all([
        store.get(epgEntryByIdAtom(request.epgId)),
        getChannelStreamUrl(store, request.channelId),
      ]);

      const now = store.get(nowSecondAtom);
      const start = epg.data ? getReplayStartTime(epg.data, now) : new Date();

      return {
        url: [
          url,
          new URLSearchParams({
            timepoint: start.toISOString(),
            ...(!store.get(drmEnabledAtom)
              ? { stream_type: isNativeHls ? "hls7" : "dash" }
              : {}),
          }).toString(),
        ].join("?"),
        offset: start.getTime(),
      };
    }
    case PlayerContentType.Recording: {
      const { recordingId } = request;
      try {
        const data = await store.get(recordingByRecordingIdAtom(recordingId));

        if (isNil(data) || data.type === "group") {
          throw new StreamNotFoundError({
            type: "recording_id_missing",
            recordingId,
          });
        }

        if (isNil(data.streamUrl)) {
          throw new StreamNotFoundError({
            type: "recording_missing_stream_url",
            recordingId,
          });
        }

        // NOTE: recordings cannot be explicitly requested without DRM (stream_type param not supported)
        return {
          url: data.streamUrl,
        };
      } catch (err) {
        if (isAxiosError(err) && err.code === "ERR_BAD_REQUEST") {
          throw new StreamNotFoundError({
            type: "recording_id_missing",
            recordingId,
          });
        }

        throw err;
      }
    }
  }
}
async function getChannelStreamUrl(
  store: Store,
  channelId: ChannelId,
): Promise<string> {
  const channel = await store.get(channelByIdAtom(channelId));

  if (!channel) {
    throw new StreamNotFoundError({
      type: "missing-channel-info",
      channelId,
    });
  }
  const url = channel.stream;

  if (isNil(url)) {
    throw new StreamNotFoundError({
      type: "missing-stream-url",
      channelId,
    });
  }

  return url;
}
