import {
  forwardRef,
  type PropsWithChildren,
  type ReactElement,
  type Ref,
} from "react";
import clsx from "clsx";

import styles from "./base-text.module.css";
import type { HeadingElements, TextElements, TextSize } from "./types";

type Props = {
  as: HeadingElements | TextElements;
  size?: TextSize;
  underline?: boolean;
  variant?: "body" | "label" | "title" | "headline" | "display";
  "aria-label"?: string;
  "aria-labelledby"?: string;
  "aria-live"?: "off" | "assertive" | "polite";
  title?: string;
  id?: string;
  "data-interactive"?: boolean;
} & PropsWithChildren &
  CommonProps;

// TODO: The typing for the ref is not fully correct, as we accept headings, spans or paragraphs.
// I believe this is not currently possible with forwardRef and TypeScript, but once we upgrade to
// React v19, refs will be passed via props and we can do a nice union type here.
// https://react.dev/blog/2024/12/05/react-19#ref-as-a-prop
const BaseText = forwardRef(function BaseText(
  {
    className,
    as = "span",
    size = "medium",
    variant = "body",
    children,
    underline,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    "data-testid": dataTestId,
    "aria-live": ariaLive,
    title,
    id,
    "data-interactive": dataInteractive,
  }: Props,
  ref: Ref<HTMLHeadingElement>,
): ReactElement {
  const Element = as;
  return (
    <Element
      ref={ref}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-live={ariaLive}
      className={clsx(
        styles.general,
        styles[size],
        styles[variant],
        underline && styles.underline,
        className,
      )}
      data-interactive={dataInteractive}
      data-testid={dataTestId}
      id={id}
      title={title}
    >
      {children}
    </Element>
  );
});

export { BaseText };
