import { featureAtom } from "@sunrise/feature-flags";

/**
 * This atom is used to store the state of the DRM enabled setting.
 * If enabled, it will force the stream to return a DASH stream without DRM.
 * This might not correctly work with Safari, since Safari likes HLS more. Then just replace 'dash' with 'hls'
 * in `create-play-request-to-stream.ts`
 * After changing the atom, you likely need to refresh the app, else playback fails.
 */
export const drmEnabledAtom = featureAtom<boolean>("drm-enabled", true, {
  description:
    "Indicates whether DRM-protected streams are enabled or not. Refresh app after changing the value. This is not interpreted when 'prefer-ng-streams' is enabled.",
});
