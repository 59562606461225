import type { HttpClient, RequestParams } from "@sunrise/backend-ng-core";

export type UpsellLink = {
  upsell_link: string;
};

export type UpsellLinkContext = "recordings" | "replay" | "upgrade" | "ads";

export type UpsellLinkSchema = {
  upsell_link: string;
};

export class Api {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  async getUpsellLink(
    params: RequestParams = {},
    query: {
      context: UpsellLinkContext;
    },
  ) {
    return this.http.request<UpsellLinkSchema>({
      path: "/upsell/v1/upsell-link",
      method: "GET",
      secure: true,
      format: "json",
      query,
      ...params,
    });
  }
}
