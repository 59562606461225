import { AxiosError } from "axios";

import { PlayerContentType } from "@sunrise/backend-ng-events";
import { ngStreamApiAtom, type StreamSchema } from "@sunrise/backend-ng-stream";
import type { SimpleStream, StreamErrorCode } from "@sunrise/backend-types";
import { selectStreamTypeAsString } from "@sunrise/jwt";
import type { StreamResolveFunction } from "@sunrise/player-manager";
import type { Store } from "@sunrise/store";
import { nowSecondAtom } from "@sunrise/time";
import { epgEntryByIdAtom } from "@sunrise/yallo-epg";
import type { PlayRequest } from "@sunrise/yallo-player-types";
import { recordingByIdNgAtom } from "@sunrise/yallo-recordings";
import { getReplayStartTime } from "@sunrise/yallo-replay";
import {
  StreamBlockedByBackendError,
  StreamNotFoundError,
} from "@sunrise/yallo-stream";

import { stringToStreamType } from "./helpers/string-to-stream-type";

const GET_RECORDING_ID_FROM_STREAM_REGEX = /(\d{2,10})/;

/**
 * We use the stream endpoints in order to play out content on the ng backend.
 */
export async function playRequestToStream(
  store: Store,
  request: PlayRequest,
): Promise<StreamResolveFunction> {
  const createSimpleStream = (data: StreamSchema): SimpleStream => {
    return {
      url: data.stream_url,
      licenseUrl: data.license_url,
      /**
       * NOTE: We get it from the JWT. But theoretically it can differ per stream.
       */
      type: stringToStreamType(store.get(selectStreamTypeAsString)),
      /**
       * WARN: This is hardcoded to zattoo ... when we ever get different stream providers the backend will need to provide this information.
       */
      provider: "zattoo",
    };
  };

  const handleError = (err: unknown): Error => {
    const axiosError =
      err instanceof AxiosError &&
      err.response?.status === 412 &&
      (err as AxiosError<{
        readonly detail: { readonly message: StreamErrorCode };
      }>);

    if (axiosError && axiosError.response?.data?.detail?.message) {
      throw new StreamBlockedByBackendError(
        axiosError.response.data.detail.message,
      );
    }

    // Fallback
    return err instanceof Error
      ? err
      : new Error(
          err instanceof Object &&
          "message" in err &&
          typeof err.message === "string"
            ? err.message
            : "unknown-error-requesting-stream",
        );
  };

  switch (request.type) {
    case PlayerContentType.Replay:
    case PlayerContentType.Live: {
      const api = store.get(ngStreamApiAtom);

      return async () => {
        const isReplay = request.type === PlayerContentType.Replay;
        // For replay we need to know the startTime of the epg entry.
        const epg = isReplay
          ? await store.get(epgEntryByIdAtom(request.epgId))
          : null;

        const now = store.get(nowSecondAtom);
        const start = epg?.data ? getReplayStartTime(epg.data, now) : null;

        try {
          const result =
            await api.stream.getChannelStreamStreamV1ChannelsChannelIdStreamGet(
              request.channelId,
              start ? { start: start.toISOString() } : {},
            );

          const base = createSimpleStream(result.data);

          if (start) {
            return {
              ...base,
              offset: start.getTime(),
            };
          }

          return base;
        } catch (err: unknown) {
          throw handleError(err);
        }
      };
    }
    case PlayerContentType.Recording: {
      return async () => {
        const api = store.get(ngStreamApiAtom);

        // Fetch recording details first. So we can get the correct providerRecordingId.
        // NOTE: Later on we could embed the providerRecordingId in the playRequest. So we would not have to do an additional request here.
        const recordingQuery = await store.get(
          recordingByIdNgAtom(request.recordingId),
        );

        if (
          !recordingQuery.data ||
          recordingQuery.data.type !== "recording" ||
          !recordingQuery.data.stream_service_url
        ) {
          throw new StreamNotFoundError({
            type: "recording_missing_stream_url",
            recordingId: request.recordingId,
          });
        }

        const providerRecordingId = GET_RECORDING_ID_FROM_STREAM_REGEX.exec(
          recordingQuery.data.stream_service_url,
        )?.[0];

        if (!providerRecordingId) {
          throw new StreamNotFoundError({
            type: "recording_missing_provider_recording_id",
            recordingId: request.recordingId,
          });
        }

        try {
          const result =
            await api.stream.getRecordingStreamStreamV1RecordingsProviderRecordingIdStreamGet(
              providerRecordingId,
            );

          return createSimpleStream(result.data);
        } catch (err) {
          throw handleError(err);
        }
      };
    }
    default: {
      throw new Error("unknown stream type");
    }
  }
}
