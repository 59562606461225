import {
  type AdPlacementResponseSchema,
  type GeneratedApi,
} from "@sunrise/backend-ng-video-ad";
import {
  type VideoAdConfig,
  type VideoAdPlacementType,
} from "@sunrise/backend-types";
import type { Nullable } from "@sunrise/utils";

export async function videoAdPlacementsQuery(
  api: GeneratedApi,
  placement: VideoAdPlacementType,
) {
  const response =
    await api.videoAd.getVideoAdPlacementVideoAdV1VideoAdPlacementsPlacementTypeGet(
      placement,
      undefined,
      {
        secure: true,
      },
    );

  return mapVideoAdPlacements(placement, response.data.items);
}

function mapVideoAdPlacements(
  placement: VideoAdPlacementType,
  items: (AdPlacementResponseSchema | null)[],
): Nullable<VideoAdConfig> {
  // we receive an array of items because the endpoint is paginated, but we'll only receive one item, so we can just map the first item
  const item = items.find((item) => item !== null);

  if (item) {
    return {
      request_type: placement,
      tags:
        item.tags
          ?.filter((item) => !!item)
          .map((tag) => ({
            tag_url: tag.url,
            impression_id: tag.impression_id,
            placement_id: item.placement_id,
            tracking: {} as Record<string, string>, // not needed on the ng backend
          })) ?? [],
      tag_count: item.tags?.length ?? 0,
      skippable: (item.skip_time ?? 0) > 0,
      skip_time_seconds: item.skip_time ?? 0,
      token_tracking_id: "",
      can_minimize: false,
    };
  }

  return null;
}
