import { atom } from "jotai";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { hostsAtom } from "@sunrise/http-client";
import { actionJWTSetTokens, jwtAtom } from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";

import { anonymousLogin } from "./auth.service";
import { deviceTypeAtom } from "./device-type.atom";

export const createAnonymousUserMutationAtom = atom(null, async (get, set) => {
  const { api } = get(hostsAtom);

  if (isNil(api)) throw new Error("Missing host");

  const deviceType = get(deviceTypeAtom);
  const isLegacyBackend = get(isLegacyBackendAtom);

  const resp = await anonymousLogin(api, deviceType, !isLegacyBackend);

  if (!resp?.client_jwt_token) throw new Error("Missing access token");
  if (!resp?.client_jwt_refresh_token) throw new Error("Missing refresh token");

  set(
    jwtAtom,
    actionJWTSetTokens({
      accessToken: resp.client_jwt_token,
      refreshToken: resp.client_jwt_refresh_token,
      wsToken: resp.client_jwt_websocket_token ?? null,
    }),
  );
});
