import areEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import type { Effect } from "jotai-effect/src/atomEffect";
import { Temporal } from "temporal-polyfill";

import { ngHttpClientConfigAtom } from "@sunrise/backend-ng-core";
import type {
  PauseStateEventSchema,
  PlayStateEventSchema,
  StopStateEventSchema,
} from "@sunrise/backend-ng-events";
import { ngEventsApiAtom, PlayerContentType } from "@sunrise/backend-ng-events";
import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import { jwtAtom } from "@sunrise/jwt";
import {
  playerCurrentContentEpgAtom,
  playerCurrentDateTimeAtom,
  type PlayerState,
  selectPlayerCurrentPlayRequest,
  selectPlayerCurrentTime,
  selectPlayerState,
} from "@sunrise/player";
import { selectProcessIsKilled } from "@sunrise/process-visibility";
import { createStableAtom } from "@sunrise/utils";

const PAUSE_TIMEOUT = 5000;

const stableCurrentContentEpgAtom = createStableAtom(
  playerCurrentContentEpgAtom,
);

type Content =
  | {
      epgId: EPGEntryId;
      startTime: Date;
      type: PlayerContentType;
    }
  | { recordingId: RecordingId; type: PlayerContentType.Recording };

/**
 * Stores the content which we reported as playing. We will always need to send a stop event for this content when we start a new one.
 */
const trackedPlayingAtom = atom<Content | null>(null);
/**
 * When we pause we want to store what is paused.
 * We'll also erase trackedPlayingAtom so that we can send a play event when we resume.
 * When we stop while paused we want to send a stop event for this paused content.
 */
const trackedPausedAtom = atom<Content | null>(null);

type CurrentContentWithState = {
  content: Content;
  state: PlayerState;
};

/**
 * Something which will give us the current content.
 * Whenever this changes to null it means we stopped playing content.
 *
 * Whenever this changes to new content, we should send a stop for the old content and send a play for this new content.
 */
const playerCurrentContentAtom = atom<CurrentContentWithState | null>((get) => {
  const pr = get(selectPlayerCurrentPlayRequest);
  if (!pr) {
    return null;
  }

  const state = get(selectPlayerState);

  if (state === "error" || state === "stopped" || state === "suspended") {
    return null;
  }

  switch (pr.type) {
    case PlayerContentType.Recording:
      return {
        content: {
          recordingId: pr.recordingId,
          type: PlayerContentType.Recording,
        },
        state,
      };
    case PlayerContentType.Live:
    case PlayerContentType.Replay: {
      // when live or replay we need to look at the current epgId.
      const epg = get(stableCurrentContentEpgAtom);

      if (!epg || !epg.epgId || !epg.schedule?.startTime) {
        return null;
      }

      return {
        content: {
          epgId: epg.epgId,
          startTime: epg.schedule.startTime,
          type: pr.type,
        },
        state,
      };
    }
    default:
      return null;
  }
});

function numberToDuration(numberInSeconds: number | null) {
  if (numberInSeconds === null) {
    return "PT0S";
  }

  const duration = new Temporal.Duration();
  return duration.add({ seconds: Math.floor(numberInSeconds) }).toString();
}

/**
 * Stores the position of the player for the current content.
 *
 * We track this separately because we need to know the position of the player when we stop playing the content.
 * It's possible that the content already switched and the player has reset its position.
 */
const positionAtom = atom<number | null>(null);

export const playerSaveProgressEffect = atomEffect((get, set) => {
  const current = get(playerCurrentContentAtom);
  const tracked = get(trackedPlayingAtom);
  const trackedPaused = get(trackedPausedAtom);
  const isKilled = get(selectProcessIsKilled);

  const position =
    current && current.content
      ? current.content.type === "recording"
        ? get(selectPlayerCurrentTime)
        : get(playerCurrentDateTimeAtom)?.getTime()
      : null;

  const areEqualInContent =
    current && tracked && areEqual(current.content, tracked);

  const api = get(ngEventsApiAtom);
  const sendCallback = async (data: EventDataType) => {
    await api.event.postEventEventV1EventsPost(data);
  };

  if (
    isKilled ||
    !current ||
    (tracked && !areEqualInContent) ||
    (!tracked && trackedPaused && !areEqual(current.content, trackedPaused))
  ) {
    const position = get(positionAtom);
    const content = tracked ?? trackedPaused;

    if (!position || !content) {
      // Should never happen ... but we will omit sending the stop event if we can't determine the position.
      set(trackedPlayingAtom, null);
      set(positionAtom, null);
      set(trackedPausedAtom, null);
      return;
    }

    // send stop
    send(
      content,
      "stop",
      position,
      isKilled ? sendAsBeacon(get) : sendCallback,
    );

    // When stop is sent we flush the current playing atom so the effect can use it to send a new play event.
    set(trackedPlayingAtom, null);
    set(positionAtom, null);
    set(trackedPausedAtom, null);
  } else if (current && !tracked && current.state === "playing") {
    // send play
    // For now we send it with a position of 0s by default. To be cleared up if this is an issue or not.
    send(current.content, "play", position ?? null, sendCallback);

    // When a play event is sent we put it in the currentPlayingAtom so we can send a stop event when the content changes.
    set(trackedPlayingAtom, current.content);
    set(trackedPausedAtom, null);
  } else if (areEqualInContent) {
    // Update the position. In case we stop we want the latest position.
    set(positionAtom, position ?? null);

    // Evaluate if the content is paused. If it is paused, we want to send a pause event.
    if (current.state === "paused") {
      // send pause on a timeout.
      const timeout = setTimeout(() => {
        send(tracked, "pause", position ?? null, sendCallback);
        // When then also clear the currentPlayingAtom so that next time around we can send a play event.
        // Once the current content is playing again.
        set(trackedPlayingAtom, null);
        // We set trackedPaused as well so that when we stop, we know what to reference.
        set(trackedPausedAtom, tracked);
      }, PAUSE_TIMEOUT);

      return () => {
        clearTimeout(timeout);
      };
    }
  }

  return;
});

function getProgressForContent(content: Content, playerTime: number | null) {
  if (playerTime === null) {
    return numberToDuration(playerTime);
  }

  switch (content.type) {
    case "replay":
    case "live":
      return Temporal.Instant.from(content.startTime.toISOString())
        .until(new Date(playerTime).toISOString())
        .toString();
    case "recording":
      return numberToDuration(playerTime);
  }

  return "PT0S";
}

type EventDataType =
  | StopStateEventSchema
  | PlayStateEventSchema
  | PauseStateEventSchema;

async function send(
  content: Content,
  event: "play" | "pause" | "stop",
  position: number | null,
  callback: (payload: EventDataType) => Promise<void>,
) {
  const progress = getProgressForContent(content, position);

  const props =
    "epgId" in content
      ? {
          progress,
          epgId: content.epgId,
          playerContentType: content.type,
          eventType: event,
        }
      : {
          progress,
          recordingId: content.recordingId,
          playerContentType: content.type,
          eventType: event,
        };

  await callback({
    recording_id: "recordingId" in props ? props.recordingId : undefined,
    epg_entry_id: "epgId" in props ? props.epgId : undefined,
    object_type: "player_state",
    client_timestamp: Date.now(),
    player_content_type: props.playerContentType,
    event_type: props.eventType,
    // We'll probably remove this in the near future but for now it has to be sent.
    player_content_provider: "zattoo",
    player_position: props.progress,
  });
}

function sendAsBeacon(get: Parameters<Effect>[0]) {
  return async (payload: EventDataType) => {
    const { accessToken } = get(jwtAtom);
    const httpConfig = get(ngHttpClientConfigAtom);
    const baseUrl = httpConfig?.baseUrl;
    const headers = {
      type: "application/json",
      Authorization: "Bearer " + accessToken,
    };
    const blob = new Blob([JSON.stringify(payload)]);
    fetch(`${baseUrl}/event/v1/events`, {
      keepalive: true,
      method: "POST",
      headers: headers,
      body: blob,
    });
  };
}
