import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { AssetFilterSchema, Availability } from "@sunrise/backend-ng-epg";
import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";
import { namespacedAtom } from "@sunrise/utils";

import type {
  RecordingGroupFilterSchema,
  RecordingStatus,
} from "../GeneratedApi";
import { assetAvailableFiltersByIdAtom } from "./asset-available-filters-by-id.atom";

/**
 * An internal readonly map of id and selected availability options.
 */
export const mappedAvailabilityAtom = namespacedAtom<
  Availability | RecordingStatus | undefined
>({
  namespace: "episode-availability",
  storage: () => sessionStorage,
});

function getAvailability(
  filters: AssetFilterSchema | RecordingGroupFilterSchema,
  availability: Availability | RecordingStatus | undefined,
) {
  const isPresent = filters?.availability.some(
    (item) => item.type === availability,
  );

  // return the availability if it is already selected
  if (availability && isPresent) {
    return availability;
  }

  // otherwise return the first item from the available filters
  return filters?.availability?.[0]?.type;
}

export const assetDetailEpisodesAvailabilityAtom = atomFamily(
  (assetId: AssetId) =>
    atom(async (get) => {
      const availability = get(mappedAvailabilityAtom(assetId));
      const filters = await get(assetAvailableFiltersByIdAtom({ assetId }));

      return getAvailability(filters.data, availability);
    }),
);

export const recordingDetailEpisodesAvailabilityAtom = atomFamily(
  (recordingGroupId: RecordingGroupId) =>
    atom(async (get) => {
      const availability = get(mappedAvailabilityAtom(recordingGroupId));
      const filters = await get(
        assetAvailableFiltersByIdAtom({ recordingGroupId }),
      );

      return getAvailability(filters.data, availability);
    }),
);
