import { isBefore } from "date-fns";
import { atom, useAtomValue } from "jotai";

import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import { getLiveProgress, nowAtom } from "@sunrise/time";
import { programIsPlayingAtTime } from "@sunrise/yallo-epg";

import { continueWatchingStatusByEpgIdAtom } from "./continue-watching-status-by-epg-id.atom";
import { continueWatchingStatusByRecordingIdAtom } from "./continue-watching-status-by-recording-id.atom";
import { getContinueWatchingProgress } from "./helpers/get-continue-watching-progress";

const NULL_ATOM = atom(() => null);

export function useContinueWatchingProgress(
  props:
    | {
        recordingId: RecordingId;
        start: Date;
        end: Date;
        paddingStartMinutes: number;
        paddingEndMinutes: number;
      }
    | { epgId: EPGEntryId; start: Date; end: Date }
    | null,
) {
  // Load relevant data.
  const status = useAtomValue(
    // TODO: When it is future content, don't even call the backend.
    props
      ? "recordingId" in props
        ? continueWatchingStatusByRecordingIdAtom(props.recordingId)
        : continueWatchingStatusByEpgIdAtom(props.epgId)
      : NULL_ATOM,
  );

  const now = useAtomValue(nowAtom);

  if (!props) {
    return {
      replayProgress: null,
      liveProgress: null,
    };
  }

  if (!status) {
    // When we have no status we may still need to display the live status when the epg is currently live.
    const liveProgress =
      props.end &&
      props.start &&
      programIsPlayingAtTime(
        { startTime: props.start, endTime: props.end },
        now,
      )
        ? getLiveProgress(props.start, props.end, now, true)
        : null;

    return {
      replayProgress: null,
      liveProgress,
    };
  }

  // Easy peasy. When it is fully watched we set it to 100%.
  if (status.isFullyWatched) {
    return {
      replayProgress: 100,
      liveProgress: 100,
    };
  }

  // When we have a status that is replay but we do not pass it an epgId, we abort.
  // When we have a status that is a recording but we do not pass it a recordingId, we abort.
  // This is because we can't show EPG progress for recordings and vice versa.
  if (
    (status.type === "replay" && !("epgId" in props)) ||
    (status.type === "recording" && !("recordingId" in props))
  ) {
    return {
      replayProgress: null,
      liveProgress: null,
    };
  }

  const { end, start } = props;

  const progress = getContinueWatchingProgress({
    status,
    epgEndTime: end,
    epgStartTime: start,
    paddingTimesInMinutes: {
      end: "paddingEndMinutes" in props ? props.paddingEndMinutes : 0,
      start: "paddingStartMinutes" in props ? props.paddingStartMinutes : 0,
    },
  });

  return {
    replayProgress: progress,
    liveProgress:
      progress && isBefore(end, now) ? 100 : getLiveProgress(start, end, now),
  };
}
