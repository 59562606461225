import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import {
  ngUpsellApiAtom,
  type UpsellLinkContext,
} from "@sunrise/backend-ng-upsell";
import { queryKeys } from "@sunrise/backend-types";
import { currentLanguageAtom } from "@sunrise/i18n";
import {
  selectCanLogin,
  selectCanUpgrade,
  selectJwtUserToken,
} from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";

/**
 * Should return an upsell URL for users who can still be upsold.
 * Aka, users who can upgrade or login.
 */
export const upsellLinkQueryAtom = atomFamily((context: UpsellLinkContext) =>
  atomWithSuspenseQuery((get) => {
    const api = get(ngUpsellApiAtom);
    const language = get(currentLanguageAtom);
    const userToken = get(selectJwtUserToken);

    if (isNil(api)) {
      throw new Error("ngUpsellApiAtom is not initialized");
    }

    const fallback = {
      upsell_link: `https://www.yallo.ch/${language}/tv-upgrade`,
    };
    const canUpgrade = get(selectCanUpgrade);
    const canLogin = get(selectCanLogin);

    return {
      queryKey: queryKeys.upsellLink(userToken, language, context),
      queryFn: async () => {
        if (!canUpgrade && !canLogin) {
          // For users who are logged in and can no longer upgrade, we don't want to show the upsell link.
          return null;
        }

        const result = await api.getUpsellLink(
          {
            validateStatus: () => true,
          },
          {
            context,
          },
        );

        if (result.status >= 200 && result.status < 300) {
          return result.data && result.data.upsell_link
            ? result.data
            : fallback;
        }

        return fallback;
      },
      retry: false,
    };
  }),
);
