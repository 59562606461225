import { atomWithMutation } from "jotai-tanstack-query";

import { endpoints } from "@sunrise/backend-types";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { isNil } from "@sunrise/utils";

export const pinValidationMutationAtom = atomWithMutation<
  boolean,
  string,
  unknown
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  return {
    mutationKey: ["pin-validation"],
    mutationFn: async (pin) => {
      const url = new URL(endpoints.approvePin(host, pin));

      try {
        await privateApi.get(url.href);

        return true;
      } catch {
        return false;
      }
    },
  };
});
