import type { ChannelId } from "@sunrise/backend-types-core";
import { namespacedAtom } from "@sunrise/utils";

/**
 * An internal readonly map of id and selected channel options.
 */
export const assetDetailEpisodesChannelAtom = namespacedAtom<ChannelId>({
  namespace: "episode-channel",
  storage: () => sessionStorage,
});
