import type { StreamType } from "@sunrise/backend-types";
import { VALID_STREAM_TYPES } from "@sunrise/backend-types";

export function stringToStreamType(type: string | null): StreamType | null {
  if (type && VALID_STREAM_TYPES.some((v) => v === type)) {
    return type as StreamType;
  }

  return null;
}
