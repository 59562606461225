import { useCallback, useEffect } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import { selectCanLogin, selectIsLoggedIn } from "@sunrise/jwt";

import { createAnonymousUserMutationAtom } from "./create-anonymous-user.mutation.atom";

export type AnonymousLoginReturn = {
  isLoggedIn: boolean;
  login: () => Promise<void>;
};

type UseAnonymousLoginProps = {
  autoLogin: boolean;
};

export function useAnonymousLogin({
  autoLogin,
}: UseAnonymousLoginProps): AnonymousLoginReturn {
  const canLogin = useAtomValue(selectCanLogin);
  const isLoggedIn = useAtomValue(selectIsLoggedIn);
  const createAnonymousUserMutation = useSetAtom(
    createAnonymousUserMutationAtom,
  );

  const login = useCallback(async () => {
    await createAnonymousUserMutation();
  }, [createAnonymousUserMutation]);

  useEffect(() => {
    if (!autoLogin) return;
    if (isLoggedIn) return;
    if (!canLogin) return;
    void login();
  }, [isLoggedIn, autoLogin, canLogin, login]);

  return {
    isLoggedIn,
    login,
  };
}
