import type {
  AssetId,
  ChannelId,
  EPGEntryId,
  Language,
  RecordingGroupId,
  RecordingId,
  TenantId,
  TimeDay,
} from "@sunrise/backend-types-core";

import type { VideoAdPlacementType } from "./ads.types";
import type { RecordingItemsSearchParams } from "./recordings.types";

/**
 * One big object with all the endpoints on the backend. Much easier than having thing spread out everywhere.
 */
export const endpoints = {
  // settings stuff
  settings: (host: string, platform: string, version: string) =>
    `${host}/settings/settings_${platform}_v${version}.json`,
  // channel stuff
  all: (host: string) => `${host}/channel/full.json`,
  channelPackages: (host: string) =>
    `${host}/alderaan/channels/from-channel-packages`,
  // channel group stuff
  staticChannelGroups: (host: string, tenantId: TenantId, lang: Language) =>
    `${host}/channel-groups/tenants/${tenantId}/${lang}/full.json`,
  userChannelGroups: (host: string) => `${host}/alderaan/channel-groups/`,
  // asset stuff
  /**
   * @returns URL to single Asset
   *
   * https://data.cdn.staging.srse.w4a.tv/assets/6523482f946cef43e08c24f6/full.json
   */
  asset: (host: string, assetId: AssetId) =>
    `${host}/assets/${assetId}/full.json`,
  staff: (host: string, assetId: AssetId) =>
    `${host}/alderaan/assets/${assetId}/staff`,
  rating: (host: string, assetId: AssetId) =>
    `${host}/kronos/assets/${assetId}/rating`,
  // epg stuff
  /**
   * @returns URL to single EPG entry
   */
  epgEntry: (host: string, epgId: EPGEntryId) =>
    `${host}/epg/entry/${epgId}/full.json`,
  /**
   * @returns URL to collection of **single channel**'s EPGs for **entire day**
   */
  epgCollectionPerChannelPerDay: (
    host: string,
    day: TimeDay,
    channelId: ChannelId,
  ) => `${host}/epg/day/${day}/${channelId}/full.json`,
  // user stuff
  me: (host: string) => `${host}/kamino/users/me`,
  // recordings stuff
  recordingItems: (
    host: string,
    searchParam?: RecordingItemsSearchParams,
  ): string => {
    const url = new URL(`${host}/alderaan/recording-items`);
    Object.entries(searchParam ?? {}).forEach(([k, v]) => {
      url.searchParams.append(k, v.toString());
    });
    return url.href;
  },
  recordingStatus: (host: string): string =>
    `${host}/alderaan/recording-status`,
  singleRecording: (host: string, id: RecordingId): string =>
    `${host}/alderaan/recordings/${id}`,
  recordingGroups: {
    recordingsGroupItems: (
      host: string,
      recordingGroupId: RecordingGroupId,
      traverseChildGroups: boolean,
    ): string =>
      `${host}/alderaan/recording-groups/${recordingGroupId}/recordings?traverse_child_groups=${traverseChildGroups.toString()}`,
    recordingsGroup: (
      host: string,
      recordingGroupId: RecordingGroupId,
    ): string => `${host}/alderaan/recording-groups/${recordingGroupId}`,
  },
  recordingSchedules: {
    create: (host: string) => `${host}/alderaan/recording-schedules`,
    deleteByEpgEntryId: (host: string, epgEntryId: EPGEntryId) =>
      `${host}/alderaan/recording-schedules/?epg_entry_id=${epgEntryId}`,
    cancelByAssetId: (host: string, assetId: AssetId) =>
      `${host}/alderaan/recording-schedules/cancel?asset_id=${assetId}`,
    haveRecordingSchedules: (host: string, assetId: AssetId) =>
      `${host}/alderaan/recording-schedules/?asset_id=${assetId}&pagesize=0`,
  },
  recordingStats: (host: string): string => `${host}/alderaan/recording-stats`,
  recordingGroupsByAssetId: (host: string, assetId: AssetId): string =>
    `${host}/alderaan/recording-groups/?asset_id=${assetId}`,
  recordingsBulkDelete: (host: string): string =>
    `${host}/alderaan/recording-items`,
  whatIsNext: (host: string, id: RecordingId): string =>
    `${host}/alderaan/recordings/${id}/next`,
  // auth stuff
  refreshToken: (host: string) => `${host}/kamino/auth/refresh`,
  invalidateAllTokens: (host: string) =>
    `${host}/kamino/users/me/test_invalidate_all_tokens`,
  invalidateAccessToken: (host: string) =>
    `${host}/kamino/users/me/test_invalidate_access_tokens`,
  anonymous: (host: string) => `${host}/kamino/users/anonymous/`,
  login: {
    withUserId: (host: string, userId: string, deviceTypeName: string) =>
      `${host}/kamino/auth/login?user_id=${userId}&device_type_name=${deviceTypeName}`,
    withToken: (host: string, token: string, deviceTypeName: string) =>
      `${host}/kamino/auth/login?id_token=${token}&device_type_name=${deviceTypeName}`,
  },
  logout: {
    currentDevice: (host: string) => `${host}/kamino/auth/logout`,
    allDevices: (host: string) =>
      `${host}/kamino/auth/logout?on_all_devices=true`,
  },
  tenantSettings: (host: string) => `${host}/kamino/settings?tenant=yallo`,
  cognito: {
    callbackLoginUrl: (host: string) => `${host}/authenticate/`,
    callbackLogoutUrl: (host: string) => `${host}/login/`,
    logoutUrl: (domain: string, clientId: string, logoutUrl: string) =>
      `https://${domain}/logout?client_id=${clientId}&logout_uri=${logoutUrl}`,
  },
  requestPin: (host: string) => `${host}/kamino/v2/pins/`,
  postPin: (host: string, pinId: string) => `${host}/kamino/v2/pins/${pinId}`,
  approvePin: (host: string, pin: string) =>
    `${host}/kamino/v2/pins/${pin}/approve`,
  // search stuff
  searchChannels: (host: string, query: string) =>
    `${host}/dagobah/search/channels?query=${query}`,
  searchEpgEntries: (host: string, query: string) =>
    `${host}/dagobah/search/epg_entries?query=${query}&epg_search_type=epg_available`,
  // GET & POST
  searchHistory: (host: string) => `${host}/dagobah/search/history`,
  // continue watching stuff
  continueWatching: (host: string) => `${host}/kamino/continue-watching`,
  fullyWatched: (host: string) => `${host}/kamino/users/me/watched`,
  // recommendations stuff
  language: (host: string, language: Language) =>
    `${host}/recommendation/${language as string}/full.json`,
  // drm
  appleFairPlayCertificate: (host: string) =>
    `${host}/security/drm/apple/fairplay/fairplay2021v1.cer`,
  // stream
  stream: (host: string, url: string) => `${host}/naboo${url}`,
  // ads
  alternativeVideoAds: (
    host: string,
    type: "replay" | "fast-forward" = "replay",
  ) => `${host}/nevarro/alternative-video-ads/${type}`,
  alternativePauseAds: (host: string) =>
    `${host}/nevarro/alternative-image-ads/pause`,
  videoAds: (host: string, placement: VideoAdPlacementType) =>
    `${host}/nevarro/video-ads/${placement}`,
};
