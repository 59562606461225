import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { recordingsStaleTimeAtom } from "@sunrise/backend-ng-recordings";
import { type LegacySingleRecording, queryKeys } from "@sunrise/backend-types";
import type { RecordingId } from "@sunrise/backend-types-core";
import { canSwallowError } from "@sunrise/error";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectIsLoggedIn, selectJwtUserToken } from "@sunrise/jwt";
import { isNil, type Nullable } from "@sunrise/utils";

import { fetchSingleRecording } from "../../recordings.service";

/**
 * This is an atom that will ask the backend for the details of a specific recordingId.
 * It will cache the results through react-query.
 */
export const recordingByRecordingIdLegacyQueryAtom = atomFamily(
  (recordingId: RecordingId) => {
    const innerAtom = atomWithSuspenseQuery<
      Nullable<LegacySingleRecording>,
      unknown,
      Nullable<LegacySingleRecording>,
      ReturnType<typeof queryKeys.recordingByRecordingId>
    >((get) => {
      const host = get(hostsAtom).api;
      if (isNil(host)) throw new Error("Host is not set");
      const { privateApi } = get(httpClientAtom);
      if (!privateApi) throw new Error("missing privateApi");

      const isLoggedIn = get(selectIsLoggedIn);

      return {
        // NOTE: We still depend on the access token since we want the data to reload when the user's token changes.
        queryKey: queryKeys.recordingByRecordingId(
          get(selectJwtUserToken),
          false,
          "all",
          recordingId,
        ),
        queryFn: async () => {
          try {
            if (!isLoggedIn) {
              return null;
            }

            return await fetchSingleRecording(host, privateApi, recordingId);
          } catch (e) {
            if (!canSwallowError(e, true)) throw e;
            return null;
          }
        },
        staleTime: get(recordingsStaleTimeAtom),
        suspense: true,
      };
    });
    innerAtom.debugLabel = `recordingByRecordingIdAtom(${recordingId})`;
    return innerAtom;
  },
);
