import { atomWithStorage, createJSONStorage } from "jotai/utils";

import { RecordingSortingOption } from "../GeneratedApi";

const storage = createJSONStorage<RecordingSortingOption>(() => sessionStorage);

/**
 * Controls which sorting to apply for the recordings overview.
 */
export const recordingsOverviewSortAtom =
  atomWithStorage<RecordingSortingOption>(
    "recordings-sorting",
    RecordingSortingOption.EpgStartDescending,
    storage,
  );
