/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AdPlacementResponseSchema */
export interface AdPlacementResponseSchema {
  /** Placement Id */
    placement_id: string;
    /**
     * Skip Time
     * @default 15
     */
    skip_time?: number;
    /** Tags */
    tags?: AdTagSchema[] | null;
  /** Event Url */
  event_url: string;
}

/** AdTagSchema */
export interface AdTagSchema {
  /** Url */
  url: string;
  /** Impression Id */
  impression_id?: string;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** Page[Union[AdPlacementResponseSchema, NoneType]] */
export interface PageUnionAdPlacementResponseSchemaNoneType {
  /** Items */
  items: (AdPlacementResponseSchema | null)[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

import { HttpClient, RequestParams } from "@sunrise/backend-ng-core";

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  videoAd = {
    /**
     * No description
     *
     * @tags video-ad-placements
     * @name GetVideoAdPlacementVideoAdV1VideoAdPlacementsPlacementTypeGet
     * @summary Get Video Ad Placement
     * @request GET:/video-ad/v1/video-ad-placements/{placement_type}
     */
    getVideoAdPlacementVideoAdV1VideoAdPlacementsPlacementTypeGet: (
      placementType: string,
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageUnionAdPlacementResponseSchemaNoneType, void | HTTPValidationError>({
        path: `/video-ad/v1/video-ad-placements/${placementType}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthVideoAdV1HealthGet
     * @summary Health
     * @request GET:/video-ad/v1/health
     */
    healthVideoAdV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/video-ad/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RootVideoAdV1Get
     * @summary Root
     * @request GET:/video-ad/v1
     */
    rootVideoAdV1Get: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/video-ad/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
