import { atom } from "jotai";

import type { RecordingGroupStatus, RecordingStatus } from "../GeneratedApi";

/**
 * Controls which status to apply for the recordings overview.
 */
export const recordingsOverviewStatusAtom = atom<
  RecordingGroupStatus | RecordingStatus | undefined
>(undefined);
