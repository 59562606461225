import {
  type EmptyAdEventSchema,
  type EndAdEventSchema,
  type ErrorAdEventSchema,
  type GeneratedApi,
  type SkippedAdEventSchema,
  type StartAdEventSchema,
} from "@sunrise/backend-ng-events";

export async function postAdEvents(
  api: GeneratedApi,
  data:
    | StartAdEventSchema
    | EndAdEventSchema
    | EmptyAdEventSchema
    | SkippedAdEventSchema
    | ErrorAdEventSchema,
) {
  return api.event.postEventEventV1EventsPost(data);
}
