import { atom } from "jotai";
import { atomFamily, atomWithStorage, createJSONStorage } from "jotai/utils";

import type { Nullable } from "./nullable.types";

export function namespacedAtom<T>(options?: {
  namespace: string;
  storage: () => Storage;
}) {
  const jsonStorage =
    options && createJSONStorage<Record<string, T>>(options.storage);
  const _namespacedAtom = options
    ? atomWithStorage<Record<string, T>>(options.namespace, {}, jsonStorage)
    : atom<Record<string, T>>({});

  return atomFamily((id: string) => {
    return atom(
      (get) => {
        const internalValue = get(_namespacedAtom)[id];
        return internalValue;
      },
      (get, set, { value }: { value: Nullable<T> }) => {
        const newMap = { ...get(_namespacedAtom) };

        if (!value) {
          delete newMap[id];
        } else {
          newMap[id] = value;
        }

        set(_namespacedAtom, newMap);
      },
    );
  });
}
