import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { type PlayRequestToStream } from "@sunrise/player-manager";
import { type Store } from "@sunrise/store";
import type { PlayRequest } from "@sunrise/yallo-player-types";

import { legacyPlayRequestToStream } from "./legacy/legacy-play-request-to-stream";
import { playRequestToStream } from "./play-request-to-stream";
import { preferNgStreamsFeatureAtom } from "./prefer-ng-streams.feature.atom";

/**
 * Converts a PlayRequest to a Stream in the context of yallo.
 *
 * This is a naive implementation since we only have live playout at the moment.
 *
 * This function when invoked with the necessary dependencies will return a function fo the `PlayRequestToStream` type.
 * This function will eventually return another function. Let's call this the `StreamResolveFunction`.
 *
 * When the first function is invoked, we will check with the backend what stream we need to start building
 * but most importantly if we can actually play it for the current user.
 * If we can't play it for the current user it needs to throw some sort of URL so that the PlayerManager can abort playout of the ads.
 *
 * The StreamResolveFunction is in place for preflight.
 * Once preflight is added, the first step will need to do the preflight request. (That will basically check if there are any reasons other than adblocker why the user can't stream it.)
 * The second step will then be called to return the final stream after the ads have played out. (Which will then run an additional check that the user did play out ads as required.)
 *
 * So for now, we need to ask for the final stream in the first step so we know before ad playout that the user can't actually play the stream.
 */
export function createPlayRequestToStream(
  store: Store,
): PlayRequestToStream<PlayRequest> {
  /**
   * Naive implementation since we only have live playout atm.
   *
   * Will check with the other modules what stream we need to request for the given PlayRequest.
   * If it can't figure out a stream, it will throw an error.
   *
   * If we have a stream URL the backend is contacted.
   * The backend can then decide to block the stream request and an error will be thrown.
   *
   * @throws {StreamNotFoundError}
   *   When we can't figure out a stream for the given PlayRequest.
   * @throws {StreamBlockedByBackendError}
   *   The stream was found but the backend is actively blocking the request with a known code.
   * @throws {Error}
   *   We can also have an unknown error from the backend. Or when we did not yet implement a stream.
   */
  return async (request: PlayRequest) => {
    const isLegacy = store.get(isLegacyBackendAtom);
    const preferNg = store.get(preferNgStreamsFeatureAtom);

    if (isLegacy || !preferNg) {
      return legacyPlayRequestToStream(store, request);
    }

    return playRequestToStream(store, request);
  };
}
