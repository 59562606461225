import { atom } from "jotai";
import { loadable } from "jotai/utils";

import type { ChannelId } from "@sunrise/backend-types-core";
import { nowAtom } from "@sunrise/time";
import type { Nullable } from "@sunrise/utils";
import { programIsPlayingAtTime } from "@sunrise/yallo-epg";

import { playerCurrentContentShownAtom } from "./player-current-content-shown.atom";
import { selectPlayerCurrentPlayRequest } from "./selectors";

type ShowLiveTag = {
  shouldShowTag: boolean;
  isAtLivePosition: boolean;
  currentChannelId: Nullable<ChannelId>;
};

export const showLiveTagAtom = atom<Promise<ShowLiveTag>>(async (get) => {
  const data = get(loadable(playerCurrentContentShownAtom));
  const now = get(nowAtom);
  const playRequest = get(selectPlayerCurrentPlayRequest);
  const currentChannelId = playRequest?.channelId;

  let shouldShowTag = false;
  let isAtLivePosition = false;

  if (data.state === "hasData") {
    const currentContent = data.data;
    const isCurrentContentLive =
      (currentContent?.schedule &&
        programIsPlayingAtTime(currentContent?.schedule, now)) ??
      false;

    if (isCurrentContentLive) {
      shouldShowTag = true;
      isAtLivePosition = playRequest ? playRequest.type === "live" : false;
    }
  }

  return {
    shouldShowTag,
    isAtLivePosition,
    currentChannelId,
  };
});
