import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { errorAtom } from "@sunrise/error";
import type { Nullable } from "@sunrise/utils";

import { VendorError } from "@/modules/vendor/vendor.error";

import { vendorQueryAtom } from "./vendor.query.atom";

const vendorCheckAtom = atom(async (get) => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get("vendor") as Nullable<string>;
  const secret = params.get("secret") as Nullable<string>;

  return get(vendorQueryAtom({ id, secret, host: window.location.hostname }));
});

const setErrorEffect = atomEffect((get, set) => {
  (async () => {
    try {
      const query = await get(vendorCheckAtom);
      if (query.error) {
        set(
          errorAtom,
          new VendorError(query.error.message, { cause: query.error }),
        );
      }
    } catch (e) {
      if (e instanceof VendorError) {
        set(errorAtom, e);
        return;
      }
      set(errorAtom, new VendorError("Unknown"));
    }
  })();
});

export const isValidVendorAtom = atom(async (get) => {
  get(setErrorEffect);

  const query = await get(vendorCheckAtom);

  return !!query.data && !query.error;
});
